export const COVEX_AUTH_URL =
  process.env.REACT_APP_COVEX_AUTH_URL ||
  'https://helena-covex-auth-staging.herokuapp.com'

export const VIEWABLE_LAYERS = [
  'Medical facilities',
  'Facility capacity',
  'Cases',
  'Case projection (1 week)',
  'Deaths',
  'Death projection (1 week)',
  'Population',
  'Poverty',
  'Comorbid conditions',
  'Social distancing',
]
export const DEFAULT_UNAUTHORIZED_VIEWABLE_LAYERS = ['Cases', 'Medical facilities']
export const DEFAULT_AUTHORIZED_VIEWABLE_LAYERS = ['Cases', 'Medical facilities']
/*
  'Hospital COVID burden',
  'Projected hospital COVID burden (1 week)',
  'Number of requests',
]
*/

export const PATHS = {
  login: '/login',
  signup: '/signup',
  dashboard: '/dashboard',
  verify: '/verify',
}
