import { computed, observable, action } from 'mobx'
import { persist } from 'mobx-persist'
import jwtDecode from 'jwt-decode'
import { COVEX_AUTH_URL } from '../constants'
import { firebase, registerUser } from '../components/firebase'
import { restartWebsockets } from '../gqlClient'

const postHeaders = {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
}

const authorizedRoles = [
  'admin',
  //'none',
  //'public',
  'outreach_agent',
]

const incorrectCredentialsMessage =
  'Incorrect username or password. Please try again.'
const incorrectCredentialsMessageBadRequest =
  'Incorrect username or password. Please try again. If you have signed up but not clicked the confirmation link, do so now.'

const loginErrorToCopy = {
  Unauthorized: incorrectCredentialsMessage,
  'Bad Request': incorrectCredentialsMessageBadRequest,
}
interface UserSignupProfile {
  email: string
  password: string
  organization: string
  organization_role: string
  resume: string
  requested_role: string
  explanation: string
  first_name: string
  last_name: string
}

class AuthStore {
  wsClient = undefined
  @observable inProgress = false
  @observable error = undefined
  @persist @observable token = undefined
  @persist @observable userId = undefined
  @persist @observable email = undefined
  @persist('object') @observable tableSearchItems = undefined

  @action setTableSearchItems = (items) => {
    this.tableSearchItems = items
  }

  @computed get user() {
    return firebase.auth().currentUser
  }

  @computed get errorMsg() {
    if (this.error in loginErrorToCopy) {
      return loginErrorToCopy[this.error]
    }

    if (this.error) return this.error

    if (this.isExpired) {
      return 'Your login session has expired. Please sign in again'
    }

    return null
  }

  @computed get tokenProps() {
    if (this.token === null || this.token === undefined) {
      return null
    }

    const [preamble, tokenStr] = this.token.split('.')
    const tokenProps = JSON.parse(window.atob(tokenStr))

    return tokenProps
  }
  @computed get profileClaim() {
    if (this.tokenProps === null) {
      return null
    }

    return this.tokenProps['https://covid.helena.org']
  }

  @computed get firstName() {
    if (this.profileClaim == undefined) {
      return null
    }

    return this.profileClaim.first_name
  }

  @computed get lastName() {
    if (this.profileClaim == undefined) {
      return null
    }

    return this.profileClaim.last_name
  }

  @computed get explanation() {
    if (this.profileClaim == undefined) {
      return null
    }

    return this.profileClaim.explanation
  }

  @computed get organization() {
    if (this.profileClaim == undefined) {
      return null
    }

    return this.profileClaim.organization
  }

  @computed get organizationRole() {
    if (this.profileClaim == undefined) {
      return null
    }

    return this.profileClaim.organization_role
  }
  @computed get externalProfile() {
    if (this.profileClaim == undefined) {
      return null
    }

    return this.profileClaim.external_profile
  }

  @computed get isExpired() {
    if (this.tokenProps === null) {
      return false
    }

    const tokenExpiresAt = this.tokenProps.exp * 1000
    const now = new Date().getTime()

    return now > tokenExpiresAt
  }

  @computed get isAuthenticated() {
    return (
      this.token !== undefined &&
      this.token !== null &&
      !this.isExpired &&
      this.isAuthorized
    )
  }

  @computed get role() {
    if (this.tokenProps === null) {
      return null
    }

    const loginInfo = this.tokenProps['https://hasura.io/jwt/claims']
    let role = undefined
    try {
      role = loginInfo['x-hasura-default-role']
    } catch (error) {
      role = undefined
    }

    return role
  }

  @computed get isAuthorized() {
    return authorizedRoles.includes(this.role)
  }

  redirectToLogin = () => this.email && !this.isAuthenticated

  @action login = async ({ email, password }) => {
    this.inProgress = true
    this.error = undefined
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password)
      const token = await firebase.auth().currentUser.getIdToken()
      const isVerified = firebase.auth().currentUser.emailVerified
      const decoded_token = jwtDecode(token)
      const hasuraClaims = decoded_token['https://hasura.io/jwt/claims']
      if (hasuraClaims === undefined || !isVerified) {
        throw Error(
          'Your account creation is in progress. Please wait until you recieve a confirmation email.'
        )
      }
      if (token == undefined) {
        throw Error(
          'Something went wrong with account authentication. Please contact support.'
        )
      }

      this.token = token
      this.email = email
      this.userId = firebase.auth().currentUser.uid
      //const decoded_token = jwtDecode(token)
      //const hasuraClaims = decoded_token['https://hasura.io/jwt/claims']
      //this.userId = hasuraClaims['x-hasura-user-id']
    } catch (error) {
      this.error = error.message
    }
    restartWebsockets(this.wsClient)
    this.inProgress = false
  }

  //@action signup = async ({ email, password }) => {
  @action signup = async (data: UserSignupProfile) => {
    this.inProgress = true
    this.error = undefined
    try {
      //await firebase.auth().createUserWithEmailAndPassword(email, password)
      const signupResult = await registerUser(data)
    } catch (error) {
      console.log(error.message)
      this.error = error.message
    }
    this.inProgress = false
  }

  @action logout = () => {
    this.email = undefined
    this.token = undefined
    this.userId = undefined
    restartWebsockets(this.wsClient)
  }
}

export default AuthStore
