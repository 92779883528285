import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Button, Form, Input, Modal, Row, Col, message } from 'antd'
import { authStore, stateStore } from '../../store/index.js'
import { PATHS } from '../../constants'
import { firebase, auth, registerUser } from '../firebase'
import { set } from 'mobx'
import { PointOfContactDisplay } from '../FacilityProfile'


const tailLayout = {
}

const SignupModal = (props) => {
  const [error, setError] = useState(null)
  const [successfulMessage, setSuccessfulMessage] = useState(null)
  const [form] = Form.useForm();
  const setVisibilityStatus = props.setVisibilityStatus

  const signupUser = async (formData) => {
    try {
      const result = await registerUser({
        ...formData, requested_role: 'outreach_agent'
      })
      message.success('Account created successfully. Check your email for more instructions.')
      return result.message
    } catch (e) {
      message.error(e.message)
    }
  }
  return (
    <Modal
      onCancel={() => setVisibilityStatus(false)}
      title="Create an Account"
      width={'600px'}
      visible={props.visible}
      footer={[]}

    >
      <PointOfContactDisplay usePassword={true} phoneRequired={false} onFinish={signupUser} showReminder={false} edit contact={{}}>        <Button key="back" onClick={(a) => setVisibilityStatus(false)}>
        Close
        </Button>
      </PointOfContactDisplay>
      <div>
      </div>


    </Modal>
  )
}

const ForgotPasswordModal = (props) => {
  const [passwordResetError, setPasswordResetError] = useState(null)
  const [successfulResetMessage, setSuccessfulResetMessage] = useState(null)
  const [form] = Form.useForm();
  const setVisibilityStatus = props.setVisibilityStatus

  const sendPasswordReset = ({ email }) => {
    const response = auth.sendPasswordResetEmail(email).then((r) => setSuccessfulResetMessage('Email sent successfully!')).catch((e) => setPasswordResetError(e.message))

  }
  return (
    <Modal
      onCancel={() => setVisibilityStatus(false)}
      title="Forgot your password?"
      visible={props.visible}
      footer={[
        <Button key="back" onClick={(a) => setVisibilityStatus(false)}>
          Close
        </Button>,
        <Button key="submit" type="primary" onClick={form.submit}>
          Send Reset Email
        </Button>,
      ]}

    >
      <Form
        form={form}
        name="basic"
        layout="vertical"
        centered
        onFinish={sendPasswordReset}
      >
        <Form.Item
          name="email"
          placeholder="Enter your Email"
          validateStatus={passwordResetError ? 'error' : successfulResetMessage ? 'success' : null}
          hasFeedback={passwordResetError || successfulResetMessage}
          help={passwordResetError || successfulResetMessage}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>
      </Form>
      <div>
      </div>


    </Modal>
  )
}
const Login = observer((props) => {
  const [passwordForgotModalVisible, setPasswordForgotModalVisible] = useState(false)
  const [signupModalVisible, setSignupModalVisible] = useState(false)
  const history = useHistory()
  const width = props.width || 540
  const onFinish = async ({ email, password }) => {
    await authStore.login({ email, password })

    if (authStore.isAuthenticated && !authStore.errorMsg) {
      history.push('/dashboard')
    }
  }
  const elementStyle = {
    width: { width },
    height: '100vh',
    //display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  }
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)

  return (
    <div style={{ height: '100vh' }}>
      <Row align='middle' justify="center" style={{ height: '80%' }}>
        <Col maxWidth='540px'>
          <img src="img/Helena-Wordmark-Blue.svg" alt="Helena" width='540px' />
          <div style={{ display: 'flex', justifyContent: 'center' }} className={'formDescription'}>
            <p>
              {!authStore.inProgress &&
                !authStore.errorMsg &&
                'Sign in to submit requests for aid or offer support.'}
              {authStore.inProgress && 'Loading…'}
              {(!authStore.inProgress && authStore.errorMsg) || ''}
            </p>
          </div>


          <Form
            name="basic"
            layout="vertical"
            centered
            width={width}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            className="ModalForm signInForm"
          >
            <div className={'signInInputs'}>
              <Form.Item
                name="email"
                label="Email"
                placeholder="Email"
                defaultValue={authStore.email}
              >
                <Input placeholder="Email" />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[{ required: false, message: 'Please input your password' }]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
            </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <a onClick={() => setPasswordForgotModalVisible(true)}>Forgot password?</a>
                  <a onClick={() => setSignupModalVisible(true)}>Create an account</a>
                </div>


            <Form.Item {...tailLayout} >

              <div style={{ display: 'flex', justifyContent: 'space-around' }}>


                <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                  Login
              </Button>
              </div>
            </Form.Item>

          </Form>
        </Col>
      </Row>
      <ForgotPasswordModal setVisibilityStatus={setPasswordForgotModalVisible} visible={passwordForgotModalVisible} />
      <SignupModal setVisibilityStatus={setSignupModalVisible} visible={signupModalVisible} />
    </div>
  )
})

export { Login }