import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

firebase.initializeApp(firebaseConfig)

type NonNullable<T> = T extends null | undefined ? never : T;
interface SendHelp {
  (data: {
    creatorJWT: string,
    user: {
      email: NonNullable<string>,
      phone: NonNullable<string>,
      first_name: NonNullable<string>,
      last_name: NonNullable<string>,
      hospital_id: NonNullable<number>,
      organization: NonNullable<string>,
      organization_role: NonNullable<string>,
    },
  }): Promise<any>
}
interface RegisterUser {
  (data: {
    email: string
    password: string
    organization: string
    organization_role: string
    resume: string
    requested_role: string
    explanation: string
    first_name: string
    last_name: string
  })
}


//  const REQUIRED_FIELDS = ["email", "password", "first_name", "last_name"];
 

interface RemindUser {
  (data: {
    creatorJWT: string,
    user: {
      email: string,
      phone: string,
      first_name: string,
      last_name: string,
    },

  }): Promise<any>
}

interface DeleteUser {
  (data: {
    creatorJWT: string,
    user: {
      email: string,
    },
  }): Promise<any>
}

export const auth = firebase.auth()
const functions = firebase.functions()
const registerUser: RegisterUser = functions.httpsCallable('registerUser')
const sendHelp: SendHelp = functions.httpsCallable('sendHelp')
const deleteUser: DeleteUser = functions.httpsCallable('deleteUser')
const sendReminder: RemindUser = functions.httpsCallable('sendReminder')
export { firebase, functions, registerUser, sendHelp, sendReminder, deleteUser }