import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import { ApolloProvider } from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { WebSocketLink } from 'apollo-link-ws'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { split } from 'apollo-link'
import { getMainDefinition } from 'apollo-utilities'
import { persistCache } from 'apollo-cache-persist'

import { App } from './components/App'
import * as serviceWorker from './serviceWorker'
import { authStore } from './store'
import localForage from "localforage";
import { createApolloClient } from './gqlClient'
import { message } from 'antd'
import './styles/hack.css'

// const httpLink = createHttpLink({
//   uri:
//     process.env.REACT_APP_HASURA_HTTP_URL ||
//     'https://helena-covid-hasura-staging.herokuapp.com/v1/graphql',
// })

// const wsLink = new WebSocketLink({
//   uri:
//     process.env.REACT_APP_HASURA_WS_URL ||
//     'wss://helena-covid-hasura-staging.herokuapp.com/v1/graphql',
//   options: {
//     reconnect: true,
//   },
// })

// const authLink = setContext((_, { headers }) => {
//   const token = authStore.token

//   // Return headers to context so httpLink can read them.
//   if (token) {
//     return {
//       headers: {
//         ...headers,
//         'authorization': 'Bearer ' + token
//       }
//     }
//   } else {
//     return headers
//   }
// }).concat(
//   onError((response) => {
//     const errors = response.errors
//     if (errors && errors.find((e) => e?.extensions?.code === 'access-denied')) {
//       console.log('ERROR LOGGING OUT')
//       authStore.logout()
//     }
//   })
// )

// const link = split(
//   // split based on operation type
//   ({ query }) => {
//     const definition = getMainDefinition(query)
//     return (
//       definition.kind === 'OperationDefinition' &&
//       definition.operation === 'subscription'
//     )
//   },
//   wsLink,
//   httpLink
// )

// const cache = new InMemoryCache()
// // await before instantiating ApolloClient, else queries might run before the cache is persisted
// await persistCache({
//   cache,
//   storage: window.localStorage,
// })

const authLinkErrorHandler = onError(({ response: { errors }, networkError, graphQLErrors }) => {
  if (errors.find((e) => e?.extensions?.code === 'access-denied')) {
    authStore.logout()
  }
  else if ((errors.find((e) => e?.extensions?.code === 'invalid-jwt' && e?.message?.includes('JWTExpired')))) {
    if (authStore.token) {
      console.log(errors.find((e) => e?.message?.includes('JWTExpired')))
      message.info("Your login session has expired. Please log back in", 10)
      authStore.logout()
    }
  }
  else if (errors) {
    console.log(errors)
    if (authStore.token) {
      console.log('Logging out with errors')
      authStore.logout()
    }
  }
})



function getAuth (tokenName) {
  if (typeof window !== 'undefined') {
    // get the authentication token from local storage if it exists
    //const token = window.localStorage.getItem(tokenName)
    const token = authStore.token
    //const token="eyJhbGciOiJSUzI1NiIsImtpZCI6ImY1YzlhZWJlMjM0ZGE2MDE2YmQ3Yjk0OTE2OGI4Y2Q1YjRlYzllZWIiLCJ0eXAiOiJKV1QifQ.eyJodHRwczovL2NvdmlkLmhlbGVuYS5vcmciOnsib3JnYW5pemF0aW9uIjoiTm9uZSIsInJlcXVlc3RlZF9yb2xlIjoiaG9zcGl0YWxfYWRtaW5pc3RyYXRvciIsImV4dGVybmFsX3Byb2ZpbGUiOm51bGwsImZpcnN0X25hbWUiOiJTaW1vbiIsImxhc3RfbmFtZSI6Ikhld2F0In0sImh0dHBzOi8vaGFzdXJhLmlvL2p3dC9jbGFpbXMiOnsieC1oYXN1cmEtZGVmYXVsdC1yb2xlIjoiY29udHJpYnV0b3IiLCJ4LWhhc3VyYS1hbGxvd2VkLXJvbGVzIjpbImNvbnRyaWJ1dG9yIl0sIngtaGFzdXJhLXVzZXItaWQiOiJ6V3BSTnBoYklOYVRjRXJ3MHgzM09kSEJZNGMyIn0sImlzcyI6Imh0dHBzOi8vc2VjdXJldG9rZW4uZ29vZ2xlLmNvbS9oZWxlbmEtY292aWQtcHJvZCIsImF1ZCI6ImhlbGVuYS1jb3ZpZC1wcm9kIiwiYXV0aF90aW1lIjoxNTkwMjE1MzQ5LCJ1c2VyX2lkIjoieldwUk5waGJJTmFUY0VydzB4MzNPZEhCWTRjMiIsInN1YiI6InpXcFJOcGhiSU5hVGNFcncweDMzT2RIQlk0YzIiLCJpYXQiOjE1OTAyMTUzNDksImV4cCI6MTU5MDIxODk0OSwiZW1haWwiOiJzaW1vbkBoZWxlbmEub3JnIiwiZW1haWxfdmVyaWZpZWQiOnRydWUsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnsiZW1haWwiOlsic2ltb25AaGVsZW5hLm9yZyJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.cJmMqOsNwhG5sV9CstFsjHAm8P8vsnHVa1cwTTh5o-j5rS0gSPA06bAYD4XwPf9l9FVtPIyAN5dmYPHCmieipkAgaXsMG2JzIeBFI42VSw9XZn2Z_iE-qMB1SszaydsAcWtqbxXAg8Jith1_mzQWInnD3eJGkrIvOfMtKmS3X_IZKwa2sHBVN1j1yeYI2zzBgN0JpvbfjaJ7-QtymE7ROkwwAwuqjPVOcekZ7Kjr5khL_zQwmhXrJECeEsIJynQmfiYfiL2MAdiYY_igwjHcLv0m0j5wV868Bv_f8BZny8ILV1vpEMx86nn3sVxitnGXalWlWO_MtZEqk4RyugquAA"
    // return the headers to the context so httpLink can read them
    return token ? `Bearer ${token}` : ''
  }
}
const AppWrapper = () => {
  const [client, setClient] = useState(undefined);
  useEffect(() => {
    (async () => {
      const cache = new InMemoryCache()
      const { apolloClient, wsClient } = createApolloClient({
        httpEndpoint: process.env.REACT_APP_HASURA_HTTP_URL || 'https://helena-covid-hasura-prod.herokuapp.com/v1/graphql',
        wsEndpoint:  process.env.REACT_APP_HASURA_WS_URL || 'wss://helena-covid-hasura-prod.herokuapp.com/v1/graphql',
        cache: cache,
        getAuth: getAuth,
        errorHandler: authLinkErrorHandler
      })

      const client = apolloClient
      authStore.wsClient = wsClient

      // const client = new ApolloClient({
      //   link: authLink.concat(link),
      //   cache: cache
      // })

      // // See above for additional options, including other storage providers.
      // await persistCache({
      //   cache,
      //   maxSize: false,
      //   storage: localForage
      // })

      setClient(client)

      return () => { };

    })()
  }, []);
  if (client === undefined) return <div><img src='img/helena.png'></img></div>
  return (
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  )
}

ReactDOM.render(
  <AppWrapper></AppWrapper>,
  document.getElementById('root')
)

serviceWorker.unregister()
