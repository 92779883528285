import React, { useState, useReducer } from 'react'
import {
  Descriptions,
  Skeleton,
  Card,
  Row,
  Col,
  Input,
  Button,
  Form,
  message,
  Spin,
  Popconfirm,
  Table,
  Space,
  Typography,
} from 'antd'
import QueueAnim from 'rc-queue-anim'
import Animate from 'rc-animate'

import { useQuery, useLazyQuery, useSubscription } from 'react-apollo'
import gql from 'graphql-tag'
import { CommentBox } from './Comments'
import { authStore } from '../store'
import { sendHelp, sendReminder, deleteUser } from './firebase'
import { set } from 'mobx'
import ProTable from '@ant-design/pro-table'

const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/

const formatPhoneString = (val) => {
  if (phoneRegex.test(val)) {
    let formattedPhoneNumber = val.replace(phoneRegex, '$1$2$3')
    return '+1' + formattedPhoneNumber
  } else {
    return undefined
  }
}

const validatePhoneNumber = (val) => {
  if (phoneRegex.test(val)) {
    return true
  } else {
    return false
  }
}

const FacilityProfileDisplay = ({ facility }) => {
  return (
    <Descriptions title="Medical Facility">
      <Descriptions.Item label="Name">
        {facility.hospital_name}
      </Descriptions.Item>
      <Descriptions.Item label="Phone">{facility.phone}</Descriptions.Item>
      <Descriptions.Item label="State">
        {facility.county.state}
      </Descriptions.Item>
      <Descriptions.Item label="Address">{facility.address}</Descriptions.Item>
    </Descriptions>
  )
}

const PointOfContactCard = ({ contact, onFinish }) => {
  const [isEditing, setEditing] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  return (
    <Card
      style={{
        width: '100%',
        background: '#ffffff',
      }}
    >
      <PointOfContactDisplay
        onFinish={onFinish}
        contact={contact}
        edit={isEditing}
      >
        {/* <Button onClick={() => setEditing(!isEditing)}>
          {isEditing ? 'Cancel' : 'Edit'}
        </Button>
 */}

        <Popconfirm
          title={() => (
            <>
              <p>Are you sure you want to delete this user?</p>
              <p>They will be removed from the Helena platform.</p>
            </>
          )}
          placement="right"
          onConfirm={() => {
            setIsDeleting(true)
            deleteUser({
              creatorJWT: authStore.token,
              user: { email: contact.email },
            })
              .then((r) => {
                message.success(`Successfully deleted user ${contact.email}`)
                setIsDeleting(false)
              })
              .catch((e) => {
                message.error(`Failed to delete user with error ${e?.message}`)
                setIsDeleting(false)
              })
          }}
          onCancel={() => {}}
          okText="Yes"
          cancelText="No"
        >
          <Button loading={isDeleting} onClick={() => {}}>
            Delete User
          </Button>
        </Popconfirm>
      </PointOfContactDisplay>
    </Card>
  )
}

const PointOfContactDisplay = ({
  contact,
  edit,
  children,
  onFinish,
  showReminder = true,
  phoneRequired = true,
  usePassword = false,
  formParams = {},
}) => {
  const [reminderSending, setReminderSending] = useState(false)
  const contactName = `${contact.first_name} ${contact.last_name}`
  const firstNameField = edit ? <Input></Input> : contact.first_name
  const lastNameField = edit ? <Input></Input> : contact.last_name
  const phoneField = edit ? <Input addonBefore="+1"></Input> : contact.phone
  const emailField = edit ? <Input></Input> : contact.email
  const organizationField = edit ? <Input></Input> : contact.organization
  const passwordField = edit ? <Input.Password></Input.Password> : ''
  const organizationRoleField = edit ? (
    <Input></Input>
  ) : (
    contact.organization_role
  )
  const [form] = Form.useForm()
  if (edit && contact.emailField) {
    form.setFieldsValue({
      first_name: contact.first_name,
      last_name: contact.last_name,
      email: contact.email,
      phone: contact.phone,
    })
  }

  return (
    <>
      <Form
        size={'large'}
        labelAlign={'left'}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        form={form}
        onFinish={onFinish}
        {...formParams}
      >
        <Form.Item
          name="email"
          label="Email"
          required={true}
          rules={[
            {
              type: 'email',
              message: 'Please input a valid email address',
            },
          ]}
        >
          {emailField}
        </Form.Item>
        {usePassword && (
          <Form.Item name="password" label="Password" required={true}>
            {passwordField}
          </Form.Item>
        )}

        <Form.Item name="first_name" label="First Name" required={true}>
          {firstNameField}
        </Form.Item>
        <Form.Item name="last_name" label="Last Name" required={true}>
          {lastNameField}
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone"
          required={phoneRequired}
          rules={
            phoneRequired && [
              {
                validator: (_, value) =>
                  validatePhoneNumber(value)
                    ? Promise.resolve()
                    : Promise.reject('Please enter a valid phone number'),
              },
            ]
          }
        >
          {phoneField}
        </Form.Item>
        <Form.Item required name="organization" label="Organization">
          {organizationField}
        </Form.Item>
        <Form.Item required name="organization_role" label="Organization Role">
          {organizationField}
        </Form.Item>
      </Form>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          {children}
          {edit && (
            <Button type="primary" onClick={() => form.submit()}>
              Submit
            </Button>
          )}
        </Col>
        {showReminder && (
          <Col span={24}>
            <Button
              type="primary"
              loading={reminderSending}
              onClick={async () => {
                try {
                  setReminderSending(true)
                  await sendReminder({
                    creatorJWT: authStore.token,
                    user: {
                      email: contact.email,
                      first_name: contact.first_name,
                      last_name: contact.last_name,
                      phone: contact.phone,
                    },
                  })
                  message.success(
                    `Sent reminder to user with email ${contact.email} and phone ${contact.phone}`
                  )
                } catch (e) {
                  message.error(`Reminder failed with error: ${e}`)
                }
                setReminderSending(false)
              }}
            >
              Send Reminder
            </Button>
          </Col>
        )}
      </Row>
    </>
  )
}

export const GET_HOSPITAL = gql`
  subscription getFacilityProfileHospital($hospital_id: Int!) {
    hospital(where: { hospital_id: { _eq: $hospital_id } }) {
      beds
      county_id
      county {
        state
        county_name
        county
      }

      hospital_name
      hospital_id
      address
      phone
      request(
        order_by: { timestamp: desc_nulls_last }
        where: { _not: { predicted: { _eq: true } } }
      ) {
        timestamp
        item
        item_quantity
        email
        request_id
      }
      ppe_requests
      risk_score_pct
      social_distance_score
      point_of_contact {
        email
        first_name
        last_name
        phone
        organization
        organization_role
      }
      city {
        city_name
      }
      staff_requests
      type
      website
      beds_pct
      comments {
        time
        user_id
        comment
        comment_id
      }
    }
  }
`

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}
// const POCData = [
//   {
//     name: 'test_name',
//     phone: '555-555-5555',
//   },
//   {
//     name: 'test_name_3',
//     phone: '555-555-5555',
//   },
// ]
const onPOCCreateFinish = async (values) => {
  const phone = formatPhoneString(values.phone)
  try {
    const pendingResponse = await sendHelp({
      creatorJWT: authStore.token,
      user: {
        email: values.email,
        phone: phone,
        first_name: values.first_name,
        last_name: values.last_name,
        hospital_id: values.hospital_id,
        organization: values.organization,
        organization_role: values.organization_role,
      },
    })
    const response = pendingResponse
    message.success('New user has been created successfully!')
    return 'success'
  } catch (error) {
    message.error(error.message)
    return error
  }
}

const FacilityProfile = (props) => {
  const facilityId = props.match?.params.facilityId
  const facilityResponse = useSubscription(GET_HOSPITAL, {
    variables: { hospital_id: facilityId },
  })
  const isLoading = facilityResponse.loading
  const isError = facilityResponse.error
  const hospitalData = facilityResponse.data?.hospital[0]
  const [contactAddOpen, setContactAddOpen] = useState(false)
  const [submitResponse, setSubmitResponse] = useState()
  const [responseInProgress, setResponseInProgress] = useState(false)
  if (isError) {
    authStore.logout()
    return <Skeleton>sdasdas</Skeleton>
  }
  if (isLoading || !hospitalData) {
    return <Skeleton></Skeleton>
  }

  const onFinish = async (values) => {
    return await onPOCCreateFinish({
      ...values,
      hospital_id: hospitalData.hospital_id,
    })
  }
  const POCData = hospitalData?.point_of_contact || []
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card
            style={{
              width: '100%',
              background: '#ffffff',
            }}
          >
            <FacilityProfileDisplay
              facility={hospitalData}
            ></FacilityProfileDisplay>
            <Button
              type="primary"
              loading={responseInProgress}
              onClick={() => setContactAddOpen(true)}
            >
              Add contact
            </Button>
          </Card>
        </Col>
        {contactAddOpen && (
          <Col span={24}>
            <Spin spinning={responseInProgress} tip="Creating Contact...">
              <Card
                title="New Contact"
                bordered={true}
                style={{
                  width: '100%',
                  background: '#ffffff',
                }}
              >
                <p>Please enter the details of the new user below.</p>
                <Animate showProp="show" transitionName="fade">
                  <PointOfContactDisplay
                    onFinish={async (values) => {
                      setResponseInProgress(true)
                      const ret = await onFinish(values)
                      setResponseInProgress(false)
                      if (ret === 'success') {
                        setContactAddOpen(false)
                      }
                    }}
                    contact={{}}
                    edit={contactAddOpen}
                  >
                    <Button onClick={() => setContactAddOpen(false)}>
                      Cancel
                    </Button>
                  </PointOfContactDisplay>
                </Animate>
              </Card>
            </Spin>
          </Col>
        )}
        <Col span={24}>
          <Card
            title="Requests"
            bordered={true}
            style={{
              width: '100%',
              background: '#ffffff',
              padding: '0',
            }}
          >
            <ProTable
              pagination={false}
              options={false}
              toolBarRender={false}
              search={false}
              columns={[
                {
                  title: 'email',
                  dataIndex: 'email',
                  render: (txt) => <div style={{ height: '100px' }}>{txt}</div>,
                },
                { title: 'id', dataIndex: 'request_id' },
                { title: 'time', dataIndex: 'timestamp' },
                { title: 'item', dataIndex: 'item' },
                { title: 'item quantity', dataIndex: 'item_quantity' },
              ]}
              dataSource={hospitalData?.request}
            ></ProTable>
          </Card>
        </Col>
        {POCData.map((c) => (
          <Col span={24}>
            <PointOfContactCard onFinish={() => {}} contact={c} />
          </Col>
        ))}

        <Col span={24}>
          <Card
            style={{
              width: '100%',
              background: '#ffffff',
            }}
          >
            <CommentBox
              facilityId={facilityId}
              user={{
                ...authStore.profileClaim,
                userId: authStore.userId,
                email: authStore.email,
              }}
            ></CommentBox>
          </Card>
        </Col>
      </Row>
    </>
  )
}
export {
  FacilityProfile,
  FacilityProfileDisplay,
  PointOfContactDisplay,
  onPOCCreateFinish,
}
