import React, { useState } from 'react'
import { Comment, Avatar, Form, Button, List, Input, Spin, Skeleton } from 'antd'
import gql from 'graphql-tag'
import { useQuery, useSubscription, useMutation } from 'react-apollo'
//import identicon from 'svg-identicon'
import { authStore } from '../../store'
import calendar from 'dayjs/plugin/calendar'
import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'
dayjs.extend(calendar)
dayjs.extend(utc)

const { TextArea } = Input

export const GET_HOSPITAL_COMMENTS = gql`
  subscription getFacilityComments($hospital_id: Int!) {
    hospital(where: { hospital_id: { _eq: $hospital_id } }) {
      comments {
        time
        comment
        comment_id
        user_id
        user {
          user_id
          email
          first_name
          last_name
        }

      }
    }
  }
`

export const DELETE_HOSPITAL_COMMENT = gql`
  mutation deleteFacilityComment($comment_id: String) {
    delete_comment(where: { comment_id: { _eq: $comment_id}  }) {
      affected_rows
    }
  }
`

export const ADD_HOSPITAL_COMMENTS = gql`
  mutation insertFacilityComnents(
    $hospital_id: Int
    $comment: String
    $user_id: String
    $time: timestamp
  ) {
    insert_comment(
      objects: {
        user_id: $user_id
        comment: $comment
        hospital_id: $hospital_id
        time: $time
      }
    ) {
      affected_rows
    }
  }
`

function insecureHashCode(s) {
  let h = 0
  for (let i = 0; i < s.length; i++) {
    h = Math.imul(31, h) + s.charCodeAt(i) | 0;
  }


    return h
}

  // function buildUserIcon(userId) {
  //   const prefix = '<?xml version="1.0"?>'
  //   const userIconText = identicon({
  //     width: 256,
  //     size: 4,
  //     hash: insecureHashCode(userId).toString(16),
  //     type: 'SQUARE',
  //   }).slice(prefix.length)

  //   return (<img src={`data:image/svg+xml;base64,${btoa(userIconText)}`} alt="" />)
  // }


const CommentList = ({ comments }) => {
  const [deleteComment, deleteCommentResponse] = useMutation(DELETE_HOSPITAL_COMMENT)
  const [deletedComments, setDeletedComments] = useState([])
  return (
    <>
    <List
      dataSource={comments}
      header={`${comments.length} ${comments.length > 1 ? 'replies' : 'reply'}`}
      itemLayout="horizontal"
      renderItem={(props: any) => {
        return(
        <Comment
          actions={props.user?.user_id === authStore.userId ? [<span onClick={() => {setDeletedComments(deletedComments.concat([props.comment_id])); deleteComment({ variables: { comment_id: props.comment_id } })}}>Delete</span>] : []}
          //avatar={buildUserIcon(props.user.user_id)}
          datetime={props.time? dayjs.utc(props.time).local().format('ddd MMM D, hh:mm:ss a'): 'Some time ago'}
          author={`${props.user?.first_name} ${props.user?.last_name}`}
          content={deletedComments.includes(props.comment_id) ? <Spin></Spin> : props.comment}
        />
      )}}
    />
    </>
  )
}

const Editor = ({ onChange, onSubmit, submitting, value }) => (
  <div>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button
        htmlType="submit"
        loading={submitting}
        onClick={onSubmit}
        type="primary"
      >
        Add Comment
      </Button>
    </Form.Item>
  </div>
)

interface CommentData {
  author: String
  avatar: String
  content: String
}

const CommentBox = (props) => {
  const [state, setState] = useState({
    comments: [],
    submitting: false,
    value: '',
  })

  const user = props.user
  const facilityId = props.facilityId

  const [addComments, addCommentsResponse] = useMutation(ADD_HOSPITAL_COMMENTS)
  const facilityComments = useSubscription(GET_HOSPITAL_COMMENTS, {
    variables: { hospital_id: facilityId },
  })
  const isLoading = facilityComments.loading
  const isError = facilityComments.error
  const commentData = facilityComments.data?.hospital[0].comments || []
  const comments = commentData
  if (isError) {
    authStore.logout()
    return <Skeleton></Skeleton>
}

  const handleSubmit = async () => {
    if (!state.value) {
      return
    }
    addComments({
      variables: {
        hospital_id: facilityId,
        user_id: user.userId,
        comment: state.value,
        time: new Date().toISOString(),
      },
    })
  }

  const handleChange = (e) => {
    setState({ ...state, value: e.target.value })
  }

  const { submitting, value } = state

  const prefix = '<?xml version="1.0"?>'
  const userId = user.userId
  // const userIconText = identicon({
  //   width: 256,
  //   size: 4,
  //   hash: insecureHashCode(userId).toString(16),
  //   type: 'SQUARE',
  // }).slice(prefix.length)
  // const UserIcon = () => (
  //   <img src={`data:image/svg+xml;base64,${btoa(userIconText)}`} alt="" />
  // )

  return (
    <div>
      {isLoading && <Spin />}
      {comments.length > 0 && <CommentList comments={comments} />}
      <Comment
        // avatar={
        //   <Avatar
        //     shape={'square'}
        //     src={`data:image/svg+xml;base64,${btoa(userIconText)}`}
        //   />
        // }
        content={
          <Editor
            onChange={handleChange}
            onSubmit={handleSubmit}
            submitting={submitting}
            value={value}
          />
        }
      />
    </div>
  )
}

export { CommentBox }
