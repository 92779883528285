import React, { useState } from 'react'
import {
  Select,
  Row,
  Col,
  Card,
  Form,
  Input,
  InputNumber,
  Button,
  List,
  Typography,
  Spin,
  Divider,
  AutoComplete,
  Modal,
  ConfigProvider,
  Empty,
  message,
} from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import gql from 'graphql-tag'
import { useQuery, useMutation } from 'react-apollo'
import { authStore } from '../../store'
import { PointOfContactDisplay, onPOCCreateFinish } from '../FacilityProfile'
import {
  getRequestData,
  getRequestData_hospital_point_of_contact,
} from '../../generated/types/getRequestData'
import { insert_multiple_requests } from '../../generated/types/insert_multiple_requests'
import { getRequest } from '../../generated/types/getRequest'
import {
  request_insert_input,
  request_obj_rel_insert_input,
} from '../../generated/types/globalTypes'
import { getFacilityProfileHospital_hospital_point_of_contact } from '../../generated/types/getFacilityProfileHospital'

const layout = {
  labelCol: { offset: 4, span: 16 },
  wrapperCol: { offset: 4, span: 16 },
}
export const AUTODATA_QUERY = gql`
  query getRequestData($hospital_id: Int!) {
    item(order_by: { item_name: asc }) {
      item_category
      item_id
      item_name
    }
    hospital(where: { hospital_id: { _eq: $hospital_id } }) {
      address
      hospital_name
      hospital_id
      county_id
      point_of_contact {
        email
        first_name
        last_name
        user_id
        organization
        organization_role
      }
    }
  }
`

export const REQUEST_QUERY = gql`
  query getRequest {
    request {
      capacity
      cases
      capacity
      request_text
      item_quantity
      item
      hospital_id
      county_id
      user_id
    }
  }
`

export const ADD_REQUEST = gql`
  mutation insert_multiple_requests($objects: [request_insert_input!]!) {
    insert_request(objects: $objects) {
      affected_rows
    }
  }
`

const validateMessages = {
  required: 'This field is required!',
  types: {
    email: '${label} is not valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
}

const questions = [
  {
    text: 'How many COVID patients, confirmed and suspected do you have?',
    label: 'cases',
    type: 'number',
  },
  {
    text:
      'How many COVID-19 patients do you have the capacity to treat safely at this moment?',
    label: 'capacity',
    type: 'number',
  },

  {
    text: 'How many tests can you run per day?',
    label: 'tests_per_day',
    type: 'number',
  },
  {
    text: 'How is the pandemic impacting your hospital?',
    label: 'hospital_pandemic_free_response',
    type: 'free_text',
  },
  {
    text: 'What are your greatest PPE need in order or priority?',
    label: 'ppe_needs',
  },
  {
    text: 'What medical equipment are you in need of?',
    label: 'medical_equipment_needs',
  },
  {
    text: 'Do you have any worries related to your finances?',
    label: 'hospital_finance_free_response',
    type: 'free_text',
  },
  {
    text: 'Do you need any additional staff?',
    label: 'additional_staff',
  },
  {
    text: 'Is there anything else we can help with?',
    label: 'request_text',
    type: 'free_text',
  },
]
const gutter = 24
const EmptyPlaceholder = (props) => (
  <Empty
    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
    imageStyle={{
      height: 60,
    }}
    description={<span>{props.description}</span>}
  >
    {props.children}
  </Empty>
)

const Request = (props) => {
  const [contactAddOpen, setContactAddOpen] = useState(false)
  const [selectedContact, setSelectedContact] = useState<
    getRequestData_hospital_point_of_contact | undefined
  >(undefined)
  const facilityId = props.match?.params.facilityId
  const [addRequest, addRequestResponse] = useMutation<
    insert_multiple_requests
  >(ADD_REQUEST)
  const autoData = useQuery<getRequestData>(AUTODATA_QUERY, {
    variables: { hospital_id: facilityId },
  })
  // categorizedItems ∷ Array ( String, Array { id: Int, name: String } )
  //   if (autoData.loading) {
  //     return (
  //       <div className="Modal-zebra">
  //         <Spin>Loading data …</Spin>
  //       </div>
  //     )


  if (addRequestResponse.data) message.info('Request made sent successfully!', 100)
  if (addRequestResponse.error) message.warning(addRequestResponse.error.message, 100)
  if (autoData.error) {
    console.error(autoData.error)
    return (
      <div className="Modal-zebra">
        <span>ERROR</span>
      </div>
    )
  }
  const categorizedItems = Object.entries(
    (autoData.data?.item || []).reduce((dict, item) => {
      const item_ = { id: item.item_id, name: item.item_name }
      const category = item.item_category.trim()
      if (category in dict) {
        dict[category].push(item_)
      } else {
        dict[category] = [item_]
      }
      return dict
    }, {})
  )
  const itemOptGroup = ([category, items], idx) => (
    <Select.OptGroup key={category} label={category}>
      {items.map(itemOption)}
    </Select.OptGroup>
  )

  // value is a hack, because the search only reads the value, NOT the label
  const itemOption = (item) => (
    <Select.Option key={item.id} value={`${item.name}|${item.id}`}>
      {item.name}
    </Select.Option>
  )

  const handleFinish = async (formData) => {
    formData.timestamp = new Date().toISOString()
    //formData.item_list = JSON.stringify(formData.item_list.map(unpipeItemId))
    formData.email = 7 // TODO: needed??
    // const [hospitalName, hospitalID, countyID] = formData.hospital_data.split(
    //   '|'
    // )
    const hospitalData = autoData.data.hospital[0]
    if (!selectedContact?.user_id) {
      message.error('A selected point of contact is necessary to submit a request.')
      return false
    }

    //const itemList = formData.item_list.map(item => item.split('|')[0])

    // Prior to request
    // create user if they do not already exist
    // add user to points of contact if they are not already there

    const requestList = formData.item_list?.map(
      (item): request_insert_input => {
        return {
          hospital_id: hospitalData.hospital_id,
          item: item.item_type.split('|')[0],
          item_quantity: item.item_quantity,
          request_text: formData.request_text,
          timestamp: formData.timestamp,
          cases: formData.cases,
          capacity: formData.capacity,
          hospital_name: hospitalData.hospital_name,
          county_id: hospitalData.county_id,
          organization_role: selectedContact.organization_role,
          user_id: selectedContact.user_id,
          email: selectedContact.email,
          other_text: JSON.stringify(formData)
        }
      }
    )
   addRequest({ variables: { objects: requestList } })

  }

  return (
    <Card
      style={{
        height: '100%',
        width: '100%',
        background: '#ffffff',
      }}
    >
      <Row justify="center">
        <Col span={24} flex="1 1">
          <Form
            {...layout}
            layout={'vertical'}
            name="nest-messages"
            onFinish={handleFinish}
            validateMessages={validateMessages}
          >
            <Form.Item required label="Point of Contact">
              <ConfigProvider
                renderEmpty={() => (
                  <EmptyPlaceholder
                    description={
                      <p>
                        There are no points of contact for this hospital. Click
                        <a onClick={() => setContactAddOpen(true)}> here</a> to
                        add one.
                      </p>
                    }
                  ></EmptyPlaceholder>
                )}
              >
                <List
                  dataSource={autoData.data?.hospital[0]?.point_of_contact}
                  renderItem={(c: getRequestData_hospital_point_of_contact) => (
                    <List.Item
                      key={c.email}
                      style={
                        c.user_id === selectedContact?.user_id
                          ? { padding: '16px', border: '1px solid #1890ff' }
                          : { padding: '16px' }
                      }
                    >
                      <List.Item.Meta
                        // avatar={
                        //   <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                        // }

                        title={`${c.first_name} ${c.last_name}`}
                        description={c.email}
                      />
                      <Button onClick={() => setSelectedContact(c)}>
                        Select
                      </Button>
                    </List.Item>
                  )}
                ></List>
              </ConfigProvider>
            </Form.Item>
            <Button type="primary" onClick={() => setContactAddOpen(true)}>
              Add New Contact
            </Button>
            <Modal
              onCancel={() => setContactAddOpen(false)}
              footer={null}
              width={800}
              visible={contactAddOpen}
            >
              <PointOfContactDisplay
                formParams={{ layout: 'vertical' }}
                contact={{}}
                edit={true}
                showReminder={false}
                onFinish={async (values) => {
                  await onPOCCreateFinish({
                    ...values,
                    hospital_id: facilityId,
                  })
                  await autoData.refetch()
                }}
              >
                <Button onClick={() => setContactAddOpen(false)}>Close</Button>
              </PointOfContactDisplay>
            </Modal>
            {/* 
            <Form.Item name="role" label="Role" rules={[{ required: true }]}>
              <Select placeholder="What's your role?">
                <Select.Option value="medical_provider">
                  HEALTHCARE PROVIDER (E.G. PHYSICIAN, NURSE)
                </Select.Option>
                <Select.Option value="executive">
                  HEALTHCARE EXECUTIVE
                </Select.Option>
                <Select.Option value="procurement">
                  PROCUREMENT STAFF
                </Select.Option>
                <Select.Option value="other">OTHER</Select.Option>
              </Select>
 */}
            <Form.Item
              label="What do you need in order to safely treat more patients? Add items in order from most to least needed."
              required
              rules={[{ required: true }]}
            >
              <Form.List name="item_list">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index) => (
                        <>
                          <Row key={field.key} gutter={gutter}>
                            <Col span={24}>
                              <Form.Item
                                name={[field.name, 'item_type']}
                                rules={[{ required: true }]}
                              >
                                <Select
                                  loading={autoData.loading}
                                  placeholder="What do you need?"
                                >
                                  {categorizedItems.map(itemOptGroup)}
                                </Select>
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row key={field.key} gutter={gutter}>
                            <Col span={24}>
                              <Form.Item
                                name={[field.name, 'item_quantity']}
                                rules={[{ required: true }]}
                              >
                                <Input placeholder="How much do you need?" />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Row key={field.key} gutter={gutter}>
                            <Col span={24}>
                              <Button
                                onClick={() => {
                                  remove(field.name)
                                }}
                                style={{
                                  width: '40%',
                                  marginLeft: '30%',
                                  marginRight: '30%',
                                  marginBottom: '20px',
                                }}
                                type="dashed"
                              >
                                Remove item
                              </Button>
                            </Col>
                          </Row>
                          <Divider />
                        </>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add()
                          }}
                          style={{ width: '100%' }}
                        >
                          <PlusOutlined /> Add item
                        </Button>
                      </Form.Item>
                    </div>
                  )
                }}
              </Form.List>
            </Form.Item>
            {questions.map((q) => {
              return (
                <Form.Item name={q.label} label={q.text}>
                  {!q.type ? (
                    <Input />
                  ) : q.type === 'number' ? (
                    <InputNumber />
                  ) : q.type === 'free_text' ? (
                    <Input.TextArea />
                  ) : (
                    <Input />
                  )}
                </Form.Item>
              )
            })}
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Card>
  )
}

export { Request }
