import { observable, action, computed } from 'mobx'
import { authStore } from '.'

class stateStore {

  //city ids of selected cities
  @observable selectedCities = []

  //Names of selected hospitals
  @observable selectedFacilities = [] 

  // Selected county IDs, string[]
  @observable selectedCounties = []
  
  // Selected state names, string[]
  @observable selectedStates = [] 

  // Selected item names, string[]
  @observable selectedItemFilters = []

  // manually set observable that tracks the total number of requests shown, eliminating a query
  @observable totalRequestsShown = 0 

  // this gets populated when a request is selected from the request view. The email modal reads it
  // it includes userId, requestId, responderFirstName, responderLastName, and itemType
  @observable selectedRequestInformation = {}
  
  // '' for none, 'SendResponseEmail', 'RequestForm'
  // 'SignupCTA', 'RequesterSignup', 'ProviderSignup'
  // 'SignupConfirm', 'RequestComplete', 'Landing',
  // 'ProviderAccountReview', 'MapLegend', 'Thank You'
  @observable currentModal = !authStore.isAuthenticated ? 'Landing' : ''
  
  // sets layer controls open or close
  @observable layerControlsOpen = false

  // is there a fresh batch of requests ready?
  @observable updatedRequests = false


}

export default stateStore
