import React from 'react'
import { Login } from './Login'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { MainLayout } from './Layout'
import { PATHS } from '../constants'
import { authStore } from '../store'
import ProtectedRoute from './ProtectedRoute'

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path={PATHS.login} component={Login} />
          <ProtectedRoute path={'/'} component={MainLayout} / >
        </Switch>
      </Router>
    </>
  )
}

export { App }