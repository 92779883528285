import React from 'react'
import {
  Layout,
  Menu,
  Form,
  Select,
  Card,
  Row,
  List,
  Col,
  Button,
  InputNumber,
  Breadcrumb,
  PageHeader,
  Tabs,
  Avatar,
  Spin,
} from 'antd'

import {
  BrowserRouter as Router,
  Route,
  Link,
  Switch,
  Redirect,
} from 'react-router-dom'
import {
  UserOutlined,
  SolutionOutlined,
  LoadingOutlined,
  SmileOutlined,
} from '@ant-design/icons'

import styled from 'styled-components'
import { RequestTable } from '../RequestTable'
import { FacilityProfile } from '../FacilityProfile'
import ProtectedRoute from '../ProtectedRoute'
import { authStore } from '../../store'
import { useHistory } from 'react-router-dom'
import { Request } from '../Request'
import gql from 'graphql-tag'
import { sendHelp } from '../firebase'
const { TabPane } = Tabs

const { Sider, Content, Header } = Layout

const Sidebar = styled(Sider)`
  background: #ffffff;
`

const LayoutContainer = styled(Layout)`
  min-height: 100vh;

`

const LayoutContent = styled(Content)`
  height: 100%;
  margin: 24px 16px;
  padding: 24;
  min-height: 280;
`
const UserFeed = () =>
        <Card style={{ width: '100%', height: '100%', background: '#ffffff' }}>
          <List
            dataSource={[{ id: 1 }, { id: 2 }]}
            renderItem={(item) => (
              <List.Item key={item.id}>
                <List.Item.Meta
                  avatar={
                    <Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
                  }
                  title={<a href="https://ant.design">{'aaaaaa'}</a>}
                  description={'aaaaa'}
                />
                <div>Content</div>
              </List.Item>
            )}
          >
            {/* {this.state.loading && this.state.hasMore && (
              <div className="demo-loading-container">
                <Spin />
              </div>
            )}
 */}
          </List>
        </Card>


const UserAccount = () => {
  return (
    <Row>
      <Col span={24}>
        <Card
          style={{ width: '100%', background: '#ffffff', minHeight: '30vh' }}
        >
          <Button>Reset Password</Button>
          <Button>Verify Email</Button>
          <Button>Contact Support</Button>
        </Card>
      </Col>
    </Row>
  )
}
const UserTable = () => {
  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Card
          style={{ width: '100%', background: '#ffffff', minHeight: '30vh' }}
        >
          <RequestTable></RequestTable>
        </Card>
      </Col>
    </Row>
  )
}

const FormItem = Form.Item
const Option = Select.Option

const breadcrumbNameMap = {
  '/dashboard': 'Dashboard',
  '/form': 'Form',
  '/account': 'My Account',
  '/facility': 'Facility',
  '/apps/1/detail': 'Detail',
  '/apps/2/detail': 'Detail',
}
const routes = [
  {
    path: '/',
    breadcrumbName: 'Home',
  },
  {
    path: '/dashboard',
    breadcrumbName: 'Dashboard',
  },
  {
    path: '/account',
    breadcrumbName: 'Account',
  },
  {
    path: '/form',
    breadcrumbName: 'Form',
  },
]

function itemRender(route, params, routes, paths) {
  const last = routes.indexOf(route) === routes.length - 1
  if (!route.breadcrumbName) { return ''}
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
  )
}
const MainLayout = (props) => {
  const history = useHistory()
  const { location } = props
  const pathSnippets = location.pathname.split('/').filter((i) => i)
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
    return {
      path: url,
      breadcrumbName: typeof url === 'string' ? breadcrumbNameMap[url] : url,
    }
  })
  const breadcrumbItems = [{ path: '/', breadcrumbName: 'Home' }].concat(
    extraBreadcrumbItems
  )
  return (
    <Layout style={{background: '#ffffff'}}>
      <LayoutContainer>
        <LayoutContent>
          <PageHeader
            onBack={
              location.pathname !== '/dashboard'
                ? () => window.history.back()
                : null
            }
            breadcrumb={{ routes: breadcrumbItems, itemRender: itemRender }}
            extra={[
              <img src="img/Helena-Wordmark-Blue.svg" alt="Helena" width='200px'/>,
              // <Button
              //   key="1"
              //   type="primary"
              //   onClick={() => {
              //     history.push('/account')
              //   }}
              // >
              //   My Account
              // </Button>,

              <Button
                key="2"
                type="primary"
                onClick={() => {
                  authStore.logout()
                  history.push('/login')
                }}
              >
                Logout
              </Button>,
            ]}
            ghost={false}
            title={breadcrumbNameMap[`/${location.pathname.split('/')[1]}`]}
            subTitle="Hospital"
          >

            <Content></Content>
          </PageHeader>

          <Switch>
            <ProtectedRoute
              path={'/facility/:facilityId'}
              component={FacilityProfile}
            />
            <ProtectedRoute path={'/dashboard'} component={UserTable} />
            <ProtectedRoute path={'/form/:facilityId'} component={Request} />
            <ProtectedRoute path={'/account'} component={UserAccount} />
            <Redirect exact from="/" to="/dashboard"></Redirect>
          </Switch>
        </LayoutContent>
      </LayoutContainer>
    </Layout>
  )
}

export { MainLayout }
