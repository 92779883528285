import React, { useState, useRef } from 'react'
import { Button, Table, Space, Tag, Input, Form, Row, Col } from 'antd'
import { useQuery, useLazyQuery, useApolloClient } from 'react-apollo'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import calendar from 'dayjs/plugin/calendar'
import gql from 'graphql-tag'
import ProTable, { IntlProvider, enUSIntl } from '@ant-design/pro-table'
import { getHospitalsMap_hospital } from '../generated/types/getHospitalsMap'
import { authStore } from '../store'
import { observer } from 'mobx-react'
dayjs.extend(calendar)
dayjs.extend(utc)

const enLocale = {
  tableFrom: {
    search: 'Query',
    reset: 'Reset',
    submit: 'Submit',
    collapsed: 'Expand',
    expand: 'Collapse',
    inputPlaceholder: 'Please enter',
    selectPlaceholder: 'Please select',
  },
  alert: {
    clear: 'Clear',
  },
  tableToolBar: {
    leftPin: 'Pin to left',
    rightPin: 'Pin to right',
    noPin: 'Unpinned',
    leftFixedTitle: 'Fixed the left',
    rightFixedTitle: 'Fixed the right',
    noFixedTitle: 'Not Fixed',
    reset: 'Reset',
    columnDisplay: 'Column Display',
    columnSetting: 'Settings',
    fullScreen: 'Full Screen',
    exitFullScreen: 'Exit Full Screen',
    reload: 'Refresh',
    density: 'Density',
    densityDefault: 'Default',
    densityLarger: 'Larger',
    densityMiddle: 'Middle',
    densitySmall: 'Compact',
  },
}

const columns = [
  {
    title: 'Risk',
    key: 'risk',
    hideInSearch: true,
    sorter: (a, b) => a.risk_score_pct - b.risk_score_pct,
    render: (text, record) => (
      <Space size="small">
        <Tag
          color={
            record.risk_score_pct < 0.25
              ? 'green'
              : record.risk_score_pct < 0.5
              ? 'yellow'
              : 'red'
          }
        >
          {record.risk_score_pct?.toFixed(3)}
        </Tag>
      </Space>
    ),
  },

  {
    title: 'Facility Name',
    dataIndex: 'hospital_name',
    key: 'hospital_name',
    render: (text, record) => (
      <Link to={`/facility/${record.hospital_id}`}>{record.hospital_name}</Link>
    ),
  },
  {
    title: 'City',
    dataIndex: ['city', 'city'],
    key: 'city',
  },
  {
    title: 'State',
    dataIndex: ['county', 'state'],
    key: 'state',
    sorter: (a, b) => a.county?.state?.localeCompare(b.county?.state),
  },
  {
    title: 'County',
    dataIndex: ['county', 'county'],
    key: 'county',
    sorter: (a, b) =>
      a.county?.county_name?.localeCompare(b.county?.county_name),
  },
  {
    title: 'Last Request',
    dataIndex: ['request', 0, 'timestamp'],
    key: 'contacted_last',
    hideInSearch: true,
    //render: (text, record) => dayjs().calendar(dayjs(text)),
    render: (a, record) => {
      const text = record.request[0]?.timestamp
      return text ? dayjs.utc(text).format('MMMM DD, YYYY') : 'NONE'
      //return text
    },
    sorter: (a, b) => {
      if (!a.request[0]?.timestamp && !!b.request[0]?.timestamp) {
        return -1
      }
      if (!!a.request[0]?.timestamp && !b.request[0]?.timestamp) {
        return 1
      }
      if (!a.request[0]?.timestamp && !b.request[0]?.timestamp) {
        return -1
      }

      return dayjs(a.request[0].timestamp).isAfter(b.request[0].timestamp)
        ? 1
        : -1
    },
  },
  {
    title: 'ID',
    dataIndex: 'hospital_id',
    key: 'hospital_id',
    hideInSearch: true,
    hideInTable: true,
  },
  {
    title: 'Phone',
    copyable: true,
    dataIndex: 'phone',
    key: 'phone',
    hideInSearch: true,
  },

  {
    title: 'Beds',
    dataIndex: 'beds',
    key: 'beds',
    sorter: (a, b) => a.beds - b.beds,
    hideInSearch: true,
  },
  {
    title: 'Action',
    key: 'action',
    render: (text, record) => (
      <Space size="small">
        <Link to={`/form/${record.hospital_id}`}>
          <Button type="primary">New Request</Button>
        </Link>
      </Space>
    ),
    hideInSearch: true,
  },
]

export const GET_HOSPITAL = gql`
  query getHospitalsMap {
    hospital(order_by: { risk_score_pct: desc_nulls_last }) {
      beds
      county_id
      county {
        county
        county_name
        state
      }
      hospital_name
      request {
        timestamp
        hospital_name
      }
      hospital_id
      phone
      ppe_requests
      risk_score_pct
      social_distance_score
      city {
        city
        city_name
      }
      staff_requests
      type
      website
      beds_pct
    }
  }
`
const RequestTable = observer(() => {
  interface ActionType {
    reload: () => void
    fetchMore: () => void
    reset: () => void
    clearSelected: () => void
    reloadAndRest: () => void
  }
  const ref = useRef<ActionType>()
  const client = useApolloClient()
  const [hospitalResponse, setClientStatus] = useState({
    loading: true,
    data: undefined,
    error: undefined,
  })
  //const  [loadOptions, hospitalResponse ] = useLazyQuery(GET_HOSPITAL)
  const isLoading = hospitalResponse.loading
  const hospitalData = hospitalResponse.data?.hospital
  const [keywords, setKeywords] = useState('')
  const defaultQueryValues = {
    hospital_name: '',
    city: '',
    county: '',
    state: '',
  }

  const searchQueries = authStore.tableSearchItems
  //const [searchQueries, setSearchQueries] = useState({})
  const [form] = Form.useForm()
  form.setFieldsValue(searchQueries)

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Form
        style={{ padding: '0 32 0 32px' }}
        form={form}
        onFinish={(dat: any) => authStore.setTableSearchItems(dat)}
      >
        <Row align="middle" justify="center" gutter={24}>
          <Col span={6}>
            <Form.Item name="hospital_name" label="Facility Name">
              <Input></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="city" label="City">
              <Input></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="state" label="State">
              <Input></Input>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="county" label="County">
              <Input></Input>
            </Form.Item>
          </Col>
          <Col flex="auto"></Col>
          <Col>
            <Button type="primary" htmlType="submit">
              Query
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() => authStore.setTableSearchItems(defaultQueryValues)}
            >
              Clear
            </Button>
          </Col>
        </Row>
      </Form>
      <IntlProvider value={enUSIntl}>
        <ProTable
          style={{ height: '100%' }}
          onRequestError={(e) => console.log(e)}
          search={false}
          actionRef={ref}
          params={searchQueries}
          request={async (params: any, sorter, filter) => {
            const response = await client.query({ query: GET_HOSPITAL })
            const hospitalData = response.data?.hospital
            setClientStatus(hospitalResponse)
            const noParams = false
            console.log(params)

            const dat = {
              data: noParams
                ? hospitalData
                : /*eslint-disable */
                  hospitalData.filter((c: getHospitalsMap_hospital) => {
                    const hospital = params.hospital_name
                      ? c.hospital_name
                          ?.toLowerCase()
                          .includes(params.hospital_name.toLowerCase())
                      : true
                    const state = params.state
                      ? c.county?.state
                          ?.toLowerCase()
                          .includes(params.state.toLowerCase())
                      : true
                    const city = params.city
                      ? c.city?.city_name
                          ?.toLowerCase()
                          .includes(params.city.toLowerCase())
                      : true
                    const county = params.county
                      ? c.county?.county_name
                          ?.toLowerCase()
                          .includes(params.county.toLowerCase())
                      : true

                    return hospital && state && city && county
                  }),
              /*eslint-enable */
            }
            return dat
          }}
          columns={columns}
          // toolBarRender={(action) => [
          //   <Input.Search
          //     style={{
          //       width: 200,
          //     }}
          //     onSearch={(value) => setKeywords(value || '')}
          //   />,
          // ]}
        />
      </IntlProvider>
    </div>
  )
})

export { RequestTable }
